var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"project-header ui grid stackable"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"three wide middle aligned column"},[_c('div',{staticClass:"margin-bottom"},[_c('img',{staticClass:"ui small centered image",attrs:{"alt":"Thumbnail du projet","src":_vm.project.thumbnail.includes('default')
              ? require('@/assets/img/default.png')
              : _vm.DJANGO_BASE_URL + _vm.project.thumbnail + _vm.refreshId()}})]),_c('div',{staticClass:"centered"},[_c('div',{staticClass:"ui basic teal label tiny-margin",attrs:{"data-tooltip":"Membres"}},[_c('i',{staticClass:"user icon",attrs:{"aria-hidden":"true"}}),_vm._v(_vm._s(_vm.project.nb_contributors)+" ")]),_c('div',{staticClass:"ui basic teal label tiny-margin",attrs:{"data-tooltip":"Signalements publiés"}},[_c('i',{staticClass:"map marker icon",attrs:{"aria-hidden":"true"}}),_vm._v(_vm._s(_vm.project.nb_published_features)+" ")]),_c('div',{staticClass:"ui basic teal label tiny-margin",attrs:{"data-tooltip":"Commentaires"}},[_c('i',{staticClass:"comment icon",attrs:{"aria-hidden":"true"}}),_vm._v(_vm._s(_vm.project.nb_published_features_comments)+" ")])])]),_c('div',{staticClass:"nine wide column"},[_c('h1',{staticClass:"ui header margin-bottom"},[_vm._v(" "+_vm._s(_vm.project.title)+" ")]),_c('div',{staticClass:"sub header"},[_c('div',{attrs:{"id":"preview"}}),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.project.description),expression:"project.description"}],attrs:{"id":"editor","data-preview":"#preview","hidden":""},domProps:{"value":(_vm.project.description)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.project, "description", $event.target.value)}}})])]),_c('div',{staticClass:"four wide column right-column"},[_c('div',{staticClass:"ui icon right compact buttons"},[(
            _vm.user &&
              _vm.permissions &&
              _vm.permissions.can_view_project &&
              _vm.isOnline
          )?_c('a',{staticClass:"ui button button-hover-green tiny-margin",attrs:{"id":"subscribe-button","data-tooltip":"Gérer mon abonnement au projet","data-position":"bottom center","data-variation":"mini"},on:{"click":function($event){return _vm.OPEN_PROJECT_MODAL('subscribe')}}},[_c('i',{staticClass:"inverted grey envelope icon",attrs:{"aria-hidden":"true"}})]):_vm._e(),(
            _vm.permissions &&
              _vm.permissions.can_update_project &&
              _vm.isOnline
          )?_c('router-link',{staticClass:"ui button button-hover-orange tiny-margin",attrs:{"id":"edit-project","to":{ name: 'project_edit', params: { slug: _vm.slug } },"data-tooltip":"Modifier le projet","data-position":"bottom center","data-variation":"mini"}},[_c('i',{staticClass:"inverted grey pencil alternate icon",attrs:{"aria-hidden":"true"}})]):_vm._e(),(_vm.isProjectAdmin && _vm.isOnline)?_c('a',{staticClass:"ui button button-hover-red tiny-margin",attrs:{"id":"delete-button","data-tooltip":"Supprimer le projet","data-position":"bottom right","data-variation":"mini"},on:{"click":function($event){return _vm.OPEN_PROJECT_MODAL('deleteProject')}}},[_c('i',{staticClass:"inverted grey trash icon",attrs:{"aria-hidden":"true"}})]):_vm._e()],1),(_vm.isProjectAdmin && !_vm.isSharedProject && _vm.project.generate_share_link)?_c('button',{staticClass:"ui teal left labeled icon button share-button tiny-margin",on:{"click":_vm.copyLink}},[_c('i',{staticClass:"left icon share square",attrs:{"aria-hidden":"true"}}),_vm._v(" Copier le lien de partage ")]):_vm._e(),(_vm.confirmMsg)?_c('div',[_c('div',{staticClass:"ui positive tiny-margin message"},[_c('span',[_vm._v(" Le lien a été copié dans le presse-papier ")]),_vm._v("   "),_c('i',{staticClass:"close icon",attrs:{"aria-hidden":"true"},on:{"click":function($event){_vm.confirmMsg = ''}}})])]):_vm._e()]),(_vm.arraysOffline.length > 0)?_c('div',{staticClass:"centered"},[_vm._v(" "+_vm._s(_vm.arraysOffline.length)+" modification"),(_vm.arraysOffline.length > 1)?_c('span',[_vm._v("s")]):_vm._e(),_vm._v(" en attente "),_c('button',{staticClass:"ui fluid labeled teal icon button",attrs:{"disabled":!_vm.isOnline},on:{"click":_vm.sendOfflineFeatures}},[_c('i',{staticClass:"upload icon",attrs:{"aria-hidden":"true"}}),_vm._v(" Envoyer au serveur ")])]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }